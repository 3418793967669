import styled from 'styled-components';

export const LayoutWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background-color: var(--secondary-background-color);

  div, input, span, p, a {
    font-family: 'Inter', 'Segoe UI', sans-serif;
  }

  h1 {
    font-size: 32px;
    font-weight: 800;
  }

  h2 {
    font-size: 28px;
    font-weight: 600;
  }

  a.dropdown-item { 
    color: var(--primary-label-color); 
  }

  &.mobile {
    height: auto;
    overflow-y: scroll;
  }

  .sticky-outer-wrapper.mobile-navbar {
    z-index: 2000;
  }
`;

interface PageWrapperProps { navBarHeight: number; }
export const PageWrapper = styled.div<PageWrapperProps>`
`;

export const SidebarWrapper = styled.div`
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  background-color: var(--background-color);
  & > * {
    width: 215px;
  }
`;

export const ContentWrapper = styled.div`
  min-height: 100vh;
  background-color: var(--secondary-background-color);
  &:not(.login) {
    margin-left: 215px;
  }
`;

export const MobileDrawerModal = `
  .devo-dialog {
    margin: 0 !important;
  }

  .modal-content {
    width: 100vw !important;
    padding: 40px 0 !important;
    border-radius: 0 !important;
    border: none !important;
  }

  .modal.fade .devo-dialog {
    transform: translateY(-200px) !important;
  }

  .modal.show .devo-dialog {
    transform: none !important;
  }
`;

export const MobileSidebarWrapper = styled.div`
  background-color: var(--background-color);
`;

export const MobileContentWrapper = styled.div<PageWrapperProps>`
  display: block;
  width: 100%;
  background-color: var(--secondary-background-color);
  min-height: calc(100vh - ${props => props.navBarHeight}px);

  &:not(.login) > div {
    padding: 10px;
  }
`;

export const Container = styled.div`
  height: 100%;
  background-color: var(--secondary-background-color);
`;

export const Wrapper = styled.div`
  padding: 50px 0;
  width: 90%;
  max-width: 850px;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 95%;
    padding: 25px 0;
  }
`;